<template>
  <div class="searson-config">
    <p class="title">季节配置</p>
    <Form ref="form" :model="form" :label-width="100">
      <Row>
        <Col span="8" offset="3">
          <div class="season">
            <p class="label">春</p>
            <div>
              <FormItem
                label="季节名称"
                :prop="'season.' + 0 + '.seasonName'"
                :rules="{ required: true, message: '请填写季节名称' }"
              >
                <Input v-model="form.season[0].seasonName"></Input>
              </FormItem>
              <FormItem
                label="季节月份"
                :prop="'season.' + 0 + '.months'"
                :rules="[
                  { required: true, type: 'array', message: '请选择季节月份' },
                  {
                    validator(rule, value, callback) {
                      let n = new Set();
                      value.forEach((item) => {
                        n.add(item);
                      });
                      form.season.forEach((el, key) => {
                        if (key == 0) return;
                        el.months.forEach((item) => {
                          if (n.has(item)) {
                            callback(rule.message);
                            return;
                          }
                        });
                      });
                      callback();
                    },
                    message: '该月份已选择',
                  },
                ]"
              >
                <Select v-model="form.season[0].months" multiple>
                  <Option
                    v-for="(item, index) in month"
                    :value="item"
                    :key="index"
                    >{{ item }}</Option
                  >
                </Select>
              </FormItem>
            </div>
          </div>
        </Col>
        <Col span="8" offset="3">
          <div class="season">
            <p class="label">夏</p>
            <div>
              <FormItem
                label="季节名称"
                :prop="'season.' + 1 + '.seasonName'"
                :rules="{ required: true, message: '请填写季节名称' }"
              >
                <Input v-model="form.season[1].seasonName"></Input>
              </FormItem>
              <FormItem
                label="季节月份"
                :prop="'season.' + 1 + '.months'"
                :rules="[
                  { required: true, type: 'array', message: '请选择季节月份' },
                  {
                    validator(rule, value, callback) {
                      let n = new Set();
                      value.forEach((item) => {
                        n.add(item);
                      });
                      form.season.forEach((el, key) => {
                        if (key == 1) return;
                        el.months.forEach((item) => {
                          if (n.has(item)) {
                            callback(rule.message);
                            return;
                          }
                        });
                      });
                      callback();
                    },
                    message: '该月份已选择',
                  },
                ]"
              >
                <Select v-model="form.season[1].months" multiple>
                  <Option
                    v-for="(item, index) in month"
                    :value="item"
                    :key="index"
                    >{{ item }}</Option
                  >
                </Select>
              </FormItem>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span="8" offset="3">
          <div class="season">
            <p class="label">秋</p>
            <div>
              <FormItem
                label="季节名称"
                :prop="'season.' + 2 + '.seasonName'"
                :rules="{ required: true, message: '请填写季节名称' }"
              >
                <Input v-model="form.season[2].seasonName"></Input>
              </FormItem>
              <FormItem
                label="季节月份"
                :prop="'season.' + 2 + '.months'"
                :rules="[
                  { required: true, type: 'array', message: '请选择季节月份' },
                  {
                    validator(rule, value, callback) {
                      let n = new Set();
                      value.forEach((item) => {
                        n.add(item);
                      });
                      form.season.forEach((el, key) => {
                        if (key == 2) return;
                        el.months.forEach((item) => {
                          if (n.has(item)) {
                            callback(rule.message);
                            return;
                          }
                        });
                      });
                      callback();
                    },
                    message: '该月份已选择',
                  },
                ]"
              >
                <Select v-model="form.season[2].months" multiple>
                  <Option
                    v-for="(item, index) in month"
                    :value="item"
                    :key="index"
                    >{{ item }}</Option
                  >
                </Select>
              </FormItem>
            </div>
          </div>
        </Col>
        <Col span="8" offset="3">
          <div class="season">
            <p class="label">冬</p>
            <div>
              <FormItem
                label="季节名称"
                :prop="'season.' + 3 + '.seasonName'"
                :rules="{ required: true, message: '请填写季节名称' }"
              >
                <Input v-model="form.season[3].seasonName"></Input>
              </FormItem>
              <FormItem
                label="季节月份"
                :prop="'season.' + 3 + '.months'"
                :rules="[
                  { required: true, type: 'array', message: '请选择季节月份' },
                  {
                    validator(rule, value, callback) {
                      let n = new Set();
                      value.forEach((item) => {
                        n.add(item);
                      });
                      form.season.forEach((el, key) => {
                        if (key == 3) return;
                        el.months.forEach((item) => {
                          if (n.has(item)) {
                            callback(rule.message);
                            return;
                          }
                        });
                      });
                      callback();
                    },
                    message: '该月份已选择',
                  },
                ]"
              >
                <Select v-model="form.season[3].months" multiple>
                  <Option
                    v-for="(item, index) in month"
                    :value="item"
                    :key="index"
                    >{{ item }}</Option
                  >
                </Select>
              </FormItem>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
    <p class="footer">
      <Button type="primary" :loading="loading" @click="submit">保存</Button>
    </p>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      form: {
        season: [
          {
            season: "",
            seasonName: "",
            months: [],
          },
          {
            season: "",
            seasonName: "",
            months: [],
          },
          {
            season: "",
            seasonName: "",
            months: [],
          },
          {
            season: "",
            seasonName: "",
            months: [],
          },
        ],
      },
      month: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      loading: false,
    };
  },
  methods: {
    getList() {
      this.$post(this.$api.SEASON_CONFIG.LIST).then(res => {
        this.form.season = res.map(item => {
          let obj = {
            ...item,
            months: item.months.split(',')
          }
          return obj
        })
      })
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.season = this.form.season.map((item) => {
          let obj = {
            ...item,
            months: item.months.join(","),
          };
          return obj;
        });
        this.loading = true;
        this.$post(this.$api.SEASON_CONFIG.EDIT, params)
          .then(() => {
            this.getList();
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
  mounted() {
    this.getList()
  }
};
</script>

<style lang="less" scoped>
.searson-config {
  width: 100%;
  height: 100%;
  padding: 16px;
  min-width: 1200px;
  .title {
    text-align: center;
    font-size: 32px;
    padding-bottom: 20px;
  }
  .season {
    display: flex;
    align-items: flex-start;
    padding-bottom: 50px;
    .label {
      font-size: 64px;
    }
    > div {
      flex-shrink: 1;
      flex-grow: 1;
    }
  }
  .footer {
    text-align: center;
  }
}
</style>